<template>
  <SCard
    :action="{
      text: 'Começar Prova',
      size: 'big',
      icon: 'arrow-right',
      disabled: true,
    }"
    class="result-card--empty"
    :title="{
      text: `Seu desempenho em ${exam.name}`,
      type: 3,
    }"
    @click="$emit('click')"
  >
    <h4
      slot="body"
      class="card__content"
    >
      Olá! Você finalizou a avaliação.
      <br>
      Aguarde o fim da aplicação para conferir o seu resultado parcial.
      <br>
      Boa sorte!
    </h4>
  </SCard>
</template>

<script>
import SCard from '@/components/SCard'

export default {
  name: 'AssessmentResultsEmpty',

  components: {
    SCard,
  },

  props: {
    exam: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="sass">

.result-card--empty
  padding: 32px 40px
  background-image: url(~@/assets/exam-result-empty.svg)
  background-repeat: no-repeat, repeat
  background-position: right
  background-position-y: 24px
  background-position-x: calc(100% - 40px)
  min-height: 328px
  justify-content: unset
  box-sizing: border-box

  .card__header
    z-index: 1

  +mq-l
    padding: 32px
    background-position-y: 160px
    background-position-x: 150%
    background-size: 50%
    min-height: 280px

  +mq-s
    padding: 24px
    background-position-y: 160px
    background-position-x: 170%
    background-size: 65%
    min-height: 248px

  +mq-xs
    padding: 16px
    min-height: 224px
    background-position-y: 160px

  .card__content
    margin-top: 72px
    margin-bottom: 40px
    width: 512px
    color: $color-ink-light
    position: relative
    z-index: 1

    +mq-m
      width: auto
      margin-top: 24px

    +mq-s
      font-size: 16px
      font-weight: 400

    +mq-xs
      margin-top: 16px
      margin-bottom: 16px
      font-size: 15px

</style>
