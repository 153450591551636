var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SCard',{staticClass:"result-card--empty",attrs:{"action":{
    text: 'Começar Prova',
    size: 'big',
    icon: 'arrow-right',
    disabled: true,
  },"title":{
    text: ("Seu desempenho em " + (_vm.exam.name)),
    type: 3,
  }},on:{"click":function($event){return _vm.$emit('click')}}},[_c('h4',{staticClass:"card__content",attrs:{"slot":"body"},slot:"body"},[_vm._v(" Olá! Você finalizou a avaliação. "),_c('br'),_vm._v(" Aguarde o fim da aplicação para conferir o seu resultado parcial. "),_c('br'),_vm._v(" Boa sorte! ")])])}
var staticRenderFns = []

export { render, staticRenderFns }