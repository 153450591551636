<template>
  <div class="card">
    <div class="card__header">
      <div
        v-html="
          `<h${title.type} class='header__title'>${title.text}</h${title.type}>`
        "
      />
      <slot name="badge" />
    </div>

    <div class="card__body">
      <slot name="body" />
    </div>

    <SasButton
      v-if="!action.disabled"
      class="card__action"
      :disabled="action.disabled"
      :size="action.size"
      @click="$emit('click')"
    >
      {{ action.text }}

      <Icon
        v-if="action.icon"
        slot="right"
        :type="action.icon"
      />
    </SasButton>
  </div>
</template>

<script>
export default {
  name: 'Card',

  props: {
    title: {
      type: Object,
      required: true,
    },

    action: {
      type: Object,
    },
  },
}
</script>

<style lang="sass">

.card
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: space-between
  border-radius: 8px
  backface-visibility: hidden
  box-shadow: 0 1px 3px rgba(0,0,0,.25)
  box-sizing: border-box
  padding: 24px
  background: white
  min-height: 216px
  position: relative
  height: 100%
  color: $color-ink

  .card__header
    width: 100%
    box-sizing: border-box
    justify-content: space-between

  .card__action
    margin: 16px 0 0 0
    position: relative
    bottom: 0

</style>
